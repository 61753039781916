.step {
	&__form {
		padding: 44px 0 90px;
		background: #f8f9fb;
		min-height: calc(100vh - 144px);
		position: relative;
		@include media('>=desktop') {
			padding: 30px 0 90px;
		}

		&__add-parent {
			min-height: calc(100vh - 244px);
		}
	}

	&__title {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;

		@include media('>=tablet') {
			font-size: 18px;
			line-height: 28px;
		}

		@include media('>=desktop') {
			font-size: 24px;
			line-height: 28px;
		}
	}

	&__subtitle {
		margin-top: 8px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;

		& strong {
			font-weight: 600;
		}
	}

	&__titles {
		margin-bottom: 16px;

		@include media('>=tablet') {
			margin-bottom: 24px;
		}
		@include media('>=desktop') {
			margin-bottom: 32px;
		}
	}

	&__desktopGrid {
		@include media('>=desktop') {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
			grid-column-gap: 24px;
		}
	}

	&__md_mt38 {
		@include media('>=desktop') {
			margin-top: 38px;
		}
	}

	&__pt_0 {
		padding-top: 0;
	}

	&__center {
		max-width: 360px;
		margin: 0 auto;
	}

	&__checkbox {
		display: flex;
		margin-top: 16px;
		@include media('>=desktop') {
			margin-top: 0px;
			align-self: baseline;
		}
	}

	&__checkboxText {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #455057;

		& a {
			color: #519bde;
			margin: 0 5px;
			text-decoration: none;
		}
	}
}
.step-buttons {
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	background: #ffffff;
	box-shadow: inset 0px 1px 0px #d8dcdf;

	&__wrapper {
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include media('>=desktop') {
			padding: 16px 21px;
			max-width: 1170px;
			width: 100%;
			margin: 0 auto;
		}
	}

	&__wrapperRight {
		padding: 16px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		@include media('>=desktop') {
			padding: 16px 21px;
			max-width: 1170px;
			width: 100%;
			margin: 0 auto;
		}
	}

	&__backBtn {
		display: flex;
		align-items: center;
		padding: 8px 16px 8px 4px !important;

		& i {
			font-size: 24px;
			margin-right: 4px;
		}
	}

	&__next {
		min-width: 97px;
	}

	&__confirm {
		min-width: 90px;
	}
}
.step-field-wrapper {
	margin-bottom: 16px !important;

	&__country-select {
		max-width: 360px;
	}

	@include media('>=tablet') {
		margin-bottom: 24px !important;
	}
}
.payment-step {
	min-height: calc(100vh - 72px);
	background: #f8f9fb;

	&__wrapper {
		padding-top: 22px;

		@include media('>=desktop') {
			padding-top: 46px;
			max-width: 360px;
		}
	}

	&__title {
		margin-top: 22px;
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 8px;

		@include media('>=tablet') {
			font-size: 24px;
			line-height: 28px;
		}
	}

	&__subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		margin-bottom: 16px;

		@include media('>=tablet') {
			margin-bottom: 24px;
		}
	}

	&__block {
		background: #ffffff;
		border: 1px solid #d8dcdf;
		border-radius: 4px;
		margin-bottom: 12px;
	}

	&__top {
		padding: 15px;
		border-bottom: 1px solid #d8dcdf;
	}
	&__bottom {
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__total {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #519bde;
	}

	&__row {
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__licence {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		margin-right: 24px;
		width: 96px;
		@include media('>=desktop') {
			width: 104px;
		}
	}

	&__child {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		margin-right: 24px;
		min-width: 56px;
		width: max-content;
		text-align: right;

		@include media('>=tablet') {
			min-width: 88px;
			width: max-content;
		}

		&:last-child {
			margin-right: 0;
		}
	}
	&__lineheader {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #6b7881;
	}

	&__disclaimer {
		display: flex;
		align-items: center;

		& p {
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			color: #6b7881;
		}

		& i {
			font-size: 16px;
			margin-right: 8px;
			color: #98a1aa;
		}
	}
}
.dashboard-step {
	min-height: calc(100vh - 144px);
	background-color: #f8f9fb;
	padding-top: 44px;
	padding-bottom: 92px;
	position: relative;

	&__add-parent-step {
		background: #f8f9fb;
		min-height: calc(100vh - 144px);
	}

	&__progress {
		text-align: left !important;
		margin-bottom: 16px;
	}

	&__title {
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 8px;

		@include media('>=tablet') {
			font-size: 24px;
			line-height: 28px;
		}
	}
	&__titles {
		margin-bottom: 16px;
		@include media('>=tablet') {
			margin-bottom: 24px;
		}
		@include media('>=desktop') {
			margin-bottom: 32px;
		}
	}

	&__subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
	}

	&__back {
		padding: 8px 16px 8px 4px !important;
		display: flex;
		align-items: center;

		& i {
			font-size: 24px;
			margin-right: 4px;
		}
	}

	&__licenceForm {
		@include media('>=desktop') {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 24px;
			align-items: start;
		}
	}

	&__payment {
		@include media('>=desktop') {
			max-width: 360px;
		}
	}
}
.add-user-step-form {
	max-width: 360px;
}