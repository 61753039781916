.table-head {
	background: #f8f9fb;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include media('<desktop') {
		width: 1128px;
	}

	&__button {
		border: none;
		padding: 0;
		display: flex;
		align-items: center;
		background: none;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;

		& i {
			font-size: 16px;
			color: #98a1aa;
			background: #ffffff;
			border-radius: 4px;
			margin-left: 8px;
		}
	}
}

.table-wrapper {
	@include media('<tablet') {
		margin: 0 -16px;
	}
}

.table-scroll-wrapper {
	border-left: 1px solid #d8dcdf;
	border-right: 1px solid #d8dcdf;
	position: relative;

	@include media('<desktop') {
		overflow: auto;
		white-space: nowrap;
	}
	@include media('<tablet') {
		border: none;
	}
}

.table-cell {
	color: #455057;
	text-align: left;
	padding: 8px 14px;
	background: #fff;
}

.table-cell-name {
	display: flex;
	align-items: center;
	width: 100%;
}

.table-cell-name > p {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.02em;
	color: #121f33;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.table-head-cell {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.02em;
	padding: 10px 14px;
	background: #f8f9fb;
}
.table-head-status {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.02em;
	padding: 10px 16px 10px 14px;
	background: #f8f9fb;
	justify-content: flex-end;
	display: flex;
	@include media('<desktop') {
		padding: 10px 16px;
	}
}
.table-email {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.02em;
	color: #3a86cb;
	text-decoration: none;
	text-wrap: wrap;
}

.table-children {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	gap: 8px;
}
.table-child {
	padding: 3px 7px;
	background: #ffffff;
	border: 1px solid #d8dcdf;
	border-radius: 2px;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
	color: #455057;

	&:hover {
		border-color: #98a1aa;
	}
}
.table-body {
	border-top: 1px solid #d8dcdf;
	border-bottom: 1px solid #d8dcdf;
	background: #fff;
	@include media('<desktop') {
		width: 1128px;
	}
}
.table-row {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	border-bottom: 1px solid #d8dcdf;

	&:last-child {
		border-bottom: none;
	}

	&:nth-last-child(-n + 2) {
		& .row-modal {
			@include media('<desktop') {
				bottom: 0;
				top: auto;
			}
		}
	}
}

.row-button-cell {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	padding: 0;
	padding-left: 8px;
	@include media('<tablet') {
		// padding: 8px;
	}

	& button {
		@include media('<tablet') {
			display: none;
		}
	}
}
.row-button {
	font-size: 24px;
	width: 40px;
	background: none;
	display: inline-block;
	border: none;
	padding: 8px;
	color: #98a1aa;
}
.table-cell-list {
	display: flex;
	justify-content: space-between;
	overflow: hidden;
}
.table-children-empty {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.02em;
	color: #121f33;
}
.table-children-button {
	font-size: 10px;
	background: none;
	margin-left: 60px;
	border: none;
	padding: 4px;
	color: #98a1aa;
	background: #f8f9fb;
	border-radius: 4px;
	transition: all 0.3s ease-in-out;
	transform: rotate(0deg);

	&__active {
		transform: rotate(180deg);
		margin-left: 0;
	}
}
.status {
	border-radius: 2px;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	padding: 4px 16px;
	display: inline-block;
	height: max-content;
	// margin: 8px;
	// margin-right: 16px;
	color: #c8c8c8;
	background: #f8f9fb;

	@include media('<tablet') {
		margin: 0;
	}
}
.status-pending {
	color: #f3bd0e;
	background: #fff6c7;
}
.status-activated {
	color: #3ebb22;
	background: #e9fbef;
}
.status-active {
	color: #3ebb22;
	background: #e9fbef;
}
.status-inactive {
	color: #de4548;
	background: #ffe3e9;
}
.status-basic, .status-staff, .status-draft {
	color: #1a3f6b;
	background: #f8f9fb;
}
.status-custom, .status-superuser, .status-published {
	color: #3a86cb;
	background: #e9f2fb;
}

.sticky-setting {
	@include media('<desktop') {
		position: sticky;
		right: 0;
		border-left: 1px solid #d8dcdf;
		box-shadow: -4px 0px 6px 0px rgb(152 161 170 / 14%);
	}
}
.sticky-name {
	@include media('<desktop') {
		position: sticky;
		left: 0;
		border-right: 1px solid #d8dcdf;
		box-shadow: 4px 0 6px 0 rgba(152, 161, 170, 0.14);
		z-index: 1;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.setting-button {
	width: 40px;
	display: block;
	background: #fff;
	z-index: 1;
}
.mobile-empty-block {
	display: block;
	width: 40px;
	height: 40px;
	background: #f8f9fb;
	z-index: 1;
}
.show-more-wrapper {
	padding: 2px;
	height: 24px;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%);
	position: absolute;
	right: -8px;
	top: 8px;
}
.table-empty {
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border-color: #d8dcdf;
	border-style: solid;
	border-width: 0 1px 1px;
	padding: 80px;
	display: none;

	@include media('<tablet') {
		border: none;
		border-bottom: 1px solid #d8dcdf;
		padding: 32px;
	}

	& h2 {
		margin-top: 20px;
		margin-bottom: 8px;
		text-align: center;
	}
	& p {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		color: #4a4d4c;
		max-width: 360px;
	}
}

.loader-container {
	margin-top: 10px;
}

.empty-show {
	display: flex;
}

.table-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #ffffff;
	border: 1px solid #d8dcdf;
	border-top: none;
	border-radius: 0 0 4px 4px;
	padding: 10px 16px;
	@include media('<tablet') {
		border-radius: 0;
		border: none;
		border-bottom: 1px solid #d8dcdf;
	}
}
.p-0 {
	padding: 0;
}
.show-children {
	flex-wrap: wrap;
}
.hide-background {
	background: none;
}