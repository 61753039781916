$fontsDir: '../../assets/fonts/';
$icons: (
	'sort-solid': '\e923',
	'child': '\e924',
	'more': '\e925',
	'sent': '\e922',
	'loading': '\e920',
	'upload': '\e921',
	'phone-solid': '\e91e',
	'school': '\e91f',
	'time': '\e92a',
	'arrow-down-bold': '\e91c',
	'info-circle': '\e91b',
	'dashboard': '\e91a',
	'edit': '\e919',
	'log-out': '\e917',
	'settings': '\e918',
	'apple': '\e900',
	'arrow-down': '\e901',
	'arrow-left': '\e902',
	'big-arrow-right': '\e903',
	'big-chevron-double-down': '\e904',
	'big-close': '\e905',
	'big-delete': '\e906',
	'big-eye-off': '\e907',
	'big-eye-on': '\e908',
	'big-menu': '\e909',
	'big-placeholder': '\e90a',
	'big-plus': '\e90b',
	'big-search': '\e90c',
	'check': '\e90d',
	'chevron-down': '\e90e',
	'chevron-left': '\e90f',
	'chevron-up': '\e910',
	'close': '\e911',
	'copy': '\e912',
	'info': '\e913',
	'minus': '\e914',
	'placeholder': '\e915',
	'plus': '\e916',
	'renew': '\e926',
	'clock': '\e927',
	'chain': '\e928',
	'upload-file': '\e929',
	'download-file': '\e91d'
);

@font-face {
	font-family: 'icomoon';
	src: url('#{$fontsDir}icomoon.woff?tz68l0') format('woff'),
		url('#{$fontsDir}icomoon.svg?tz68l0#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@each $icon, $value in $icons {
	.icon-#{$icon}:before {
		content: unquote("'#{$value}'");
	}
}
