.container {
	padding: 0 16px;

	@include media('>=tablet') {
		padding: 0 24px;
	}
	@include media('>=desktop') {
		padding: 0 21px;
		max-width: 1170px;
		width: 100%;
		margin: 0 auto;
	}
}

.container-md-center {
	@include media('>=tablet') {
		max-width: 352px;
		margin: 0 auto;
		padding: 0;
	}
	@include media('>=desktop') {
		max-width: 360px;
	}
}

.container-lg-center {
	@include media('>=desktop') {
		max-width: 744px;
	}
}

.hideOnMobile {
	display: none;
	@include media('>desktop') {
		display: block;
	}
}
