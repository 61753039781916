.error-boundary {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #455057;
  }

  &__message {
    color: #121f33;
  }

  &__reload {
    background: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #3a86cb;
    text-decoration: none;
    margin-right: 4px;
    border: none;
    padding: 0;
  }

  &__subtitle {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #455057;
  }
}