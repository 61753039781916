@import url('./shared/assets/fonts/montserrat/Montserrat.css');

body {
	margin: 0;
	font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	font-size: 24px;
	font-weight: 600;
	line-height: 28px;
}

h2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
}

h2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
}
