.btn {
	border-radius: 4px;
	padding: 10px 16px;
	text-align: center;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.02em;
	border: none;
	text-align: center;
}
.outlined {
	border: 1px solid #4a93d6;
	background: #fff;
	color: #4a93d6;

	&:disabled {
		background-color: #f8f9fb;
		border-color: #d8dcdf;
		color: #d8dcdfff;
	}
}

.primary {
	background: #4a93d6;
	color: #ffffff;
	line-height: 20px;

	&:disabled {
		background: #98a1aa;
		color: #d8dcdf;
	}
}

.secondary {
	background: #1a3f6b;
	color: #ffffff;
}
.danger {
	background: #de4548;
	color: #ffffff;
	line-height: 20px;
}

.empty {
	color: #519bde;
	background: none;

	&:disabled {
		background: #f8f9fb;
		color: #98a1aa;
	}
}

.underlined {
	background: none;
	color: #3A86CB;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	border-width:  0 0 1px 0;
	border-radius: 0;
	border-style: solid;
	border-color: #3A86CB;
	padding: 0;

	&:disabled {
		color: #6B7881;
		border-color: #6B7881;
	}
}

._block {
	display: block;
	width: 100%;
}
