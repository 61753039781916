.dashboard {
	background: #f8f9fb;
	padding: 32px 0;
	height: 100%;
	min-height: calc(100dvh - 72px);

	@include media('<tablet') {
		overflow: hidden;
	}

	&--admin {
		@include media('<tablet') {
			overflow: auto;
			max-height: 80svh;
		}
	}

	@include media('>=tablet') {
		padding: 40px 0 50px;
	}

	&__hello {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #455057;

		@include media('>=tablet') {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: -0.02em;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 16px;

		@include media('>=tablet') {
			margin-bottom: 0;
		}
	}

	&__children {
		@include media('>=tablet') {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 16px;
			place-items: flex-start;
		}
		@include media('>=desktop') {
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 16px;
			grid-row-gap: 8px;
		}
	}

	&__top {
		margin-bottom: 16px;
		display: flex;
		flex-direction: column;

		@include media('>=tablet') {
			flex-direction: row;
			margin-bottom: 24px;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__plusbtn {
		display: flex;
		align-items: center;
		justify-content: center;
		@include media('<tablet') {
			width: 100%;
		}

		& i {
			font-size: 16px;
			margin-right: 8px;
			width: 16px;
		}
	}

	&__btnwrapper {
		margin-bottom: 16px;
		display: flex;
		justify-content: flex-end;
	}
}

.dashboard-tabs {
	&__list {
		border-bottom: 1px solid #d8dcdf;
		display: flex;
		margin-bottom: 16px;

		@include media('<tablet') {
			max-width: 100%;
			overflow-x: auto;
		}
	}

	&__tab {
		padding: 8px 16px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		cursor: pointer;
		outline: none;
	}

	&__tabActive {
		padding: 8px 16px 6px;
		color: #3a86cb;
		border-bottom: 2px solid #3a86cb;
	}
}
.parents {
	&__actionButton {
		height: 100%;
		padding: 8px !important;
		display: flex;
		align-items: center;

		&__icon {
			font-size: 20px;
			width: 24px;
		}
	}
}
.report {
	&__filters {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 12px;

		&__adminTab {
			@include media('<tablet') {
				flex-direction: column;
			}

			& > &__select {
				width: 256px;

				@include media('<tablet') {
					width: 100%;
					margin-bottom: 8px;
				}
			}
		}

		& > &__select {
			min-width: 130px;
			width: fit-content;
		}
	}

	&__filter-right {
		display: flex;
		align-items: center;

		@include media('<tablet') {
			flex-direction: column;
			width: 100%;
		}
	}

	&__export-button {
		margin-left: 8px;
		display: flex;
		align-items: center;
		width: 40px;
		justify-content: center;
		padding: 10px !important;

		@include media('<tablet') {
			margin-left: 0px;
			margin-top: 8px;
			display: block;
			width: 100%;
		}

		& .icon-download-file {
			font-size: 20px;
			width: 24px;
		}
	}

	&__metrics {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 0;
		grid-row-gap: 0;
		margin-bottom: 12px;
		grid-template-rows: auto;

		@include media('>=desktop') {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: 24px;
		}
	}

	&__stats {
		display: flex;
		gap: 24px;
		flex-direction: column;

		@include media('>=desktop') {
			flex-direction: row;
		}
	}

	&__item {
		padding: 7px 15px;
		background: #ffffff;
		border: 1px solid #d8dcdf;
		text-align: center;

		@include media('>=desktop') {
			padding: 16px;
		}
	}

	.m1 {
		grid-area: 1 / 1 / 2 / 2;
		border-radius: 4px 0px 0px 0px;

		@include media('>=desktop') {
			grid-area: 1 / 1 / 2 / 2;
			border-radius: 4px;
		}
	}
	.m2 {
		grid-area: 1 / 2 / 2 / 3;
		border-radius: 0px 4px 0px 0px;

		@include media('>=desktop') {
			grid-area: 1 / 2 / 2 / 3;
			border-radius: 4px;
		}
	}
	.m3 {
		grid-area: 2 / 1 / 3 / 3;
		border-radius: 0px 0px 4px 4px;
		height: fit-content;

		@include media('>=desktop') {
			grid-area: 1 / 3 / 2 / 4;
			height: auto;
			border-radius: 4px;
		}
	}

	&__quantity {
		font-weight: 400;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.02em;

		@include media('>=tablet') {
			font-weight: 600;
		}
	}

	&__span {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #121f33;
	}

	& .blocked {
		color: #de4548;
	}
	& .all-queries {
		color: #519bde;
	}

	&__percent {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #98a1aa;

		& span {
			@include media('>tablet') {
				font-weight: 600;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: -0.02em;
				color: #98a1aa;
				display: block;
			}
		}
	}

	&__graphics {
		display: flex;
		flex-direction: column;

		@include media('>=desktop') {
			flex-direction: row;
			align-items: flex-start;
			width: 100%;
			column-gap: 24px;
		}
	}

	&__graphics--admin {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 8px;
	}
}

.graph {
	border: 1px solid #d8dcdf;
	border-radius: 4px;
	box-sizing: border-box;
	background-color: #fff;

	&--half-width {
		margin-bottom: 16px;

		@include media('>=desktop') {
			max-width: 50%;
			margin-bottom: 0;
			flex: 1;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 2px;
	}

	&__subtitle {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #6b7881;
	}

	&__header {
		padding: 8px 16px;

		&--border {
			border-bottom: 1px solid #d8dcdf;
		}

		&--dashboard {
			padding: 0;
			margin-bottom: 0;

			h3 {
				font-weight: 600;
				font-size: 18px;
				line-height: 20px;
			}

			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
	&__area {
		max-width: 100%;
		min-height: 72px;

		&__wrapper {
			padding: 8px 16px 16px;
		}

		@include media('>=tablet') {
			min-height: 149px;
			// width: 100%;
		}
	}
}
.graph-custom-tooltip {
	background: #ffffff;
	border: 1px solid #d8dcdf;
	border-radius: 4px;
	padding: 11px 15px;
	gap: 8px;
	outline: none;

	&__date {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #121f33;
		margin-bottom: 8px;
	}

	&__queries {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #6b7881;

		&:last-child {
			margin-top: 4px;
		}
	}

	&__granted {
		color: #519bde;
	}
	&__blocked {
		color: #de4548;
	}
}
.reasons {
	border: 1px solid #d8dcdf;
	border-radius: 4px;
	background: #ffffff;
	flex: 1;
	height: max-content;

	&--bottomless {
		border-bottom: 0;
	}

	@include media('>=desktop') {
		max-width: 50%;
	}

	&__header {
		padding: 7px 15px;
		border-bottom: 1px solid #d8dcdf;
	}

	&__list {
		max-height: 132px;
		height: max-content;
		overflow: hidden;
	}

	&__openList {
		max-height: unset;
	}

	&__title {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 2px;
	}

	&__subtitle {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #6b7881;
	}

	&__item {
		padding: 8px 16px;
		border-bottom: 1px solid #d8dcdf;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #455057;
	}

	&__btn {
		width: 100%;
		display: flex;
		justify-content: space-between;
		border: none;
		outline: none;
		background: none;
		padding: 0;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #3a86cb;
		border-radius: 0px 0px 4px 4px;
		padding: 8px 16px;

		&--bottom-border {
			border-bottom: 1px solid #d8dcdf;
		}

		& .icon-chevron-down {
			font-size: 16px;
			transition: 300ms all;
		}
	}

	&__active {
		& .icon-chevron-down {
			transform: rotate(180deg);
		}
	}

	&__stats {
		width: 100%;
		font-family: 'Montserrat';
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		border-spacing: 0;

		&:last-child {
			border-radius: 0 0 4px 4px;
		}

		tbody {
			transition: all 0.6s ease;
		}

		&__headers {
			th {
				font-weight: 500;
				color: #6b7881;
				background: #f8f9fb;
				padding: 8px 16px;
				text-align: start;
				border-bottom: 1px solid #d8dcdf;

				&:not(:last-child) {
					border-right: 1px solid #d8dcdf;
				}
			}
		}

		&__row {
			color: #455057;

			td {
				padding: 8px 16px;
				text-align: start;
				border-bottom: 1px solid #d8dcdf;

				&:not(:last-child) {
					border-right: 1px solid #d8dcdf;
				}
			}
		}
	}
}
